import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vld-parent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_loading, {
        active: _ctx.showLoading,
        "z-index": "1056",
        "lock-scroll": true,
        opacity: 0.8
      }, null, 8, ["active", "opacity"])
    ])
  ], 64))
}